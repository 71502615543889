import React from "react";
import { Color } from "../types/Color";
import { typeToString } from "../extensions/helpers";

interface ColorSelectorProps {
  selectedColor: Color;
  onColorSelect: (color: Color) => void;
  availableColors?: Color[];
}

const ColorSelector: React.FC<ColorSelectorProps> = ({
  selectedColor,
  onColorSelect,
  availableColors,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onColorSelect(Color[event.target.value as keyof typeof Color]);
  };

  return (
    <select
      id="color"
      name="color"
      className="border border-gray-300 p-2 rounded-lg w-full"
      value={selectedColor}
      onChange={handleChange}
    >
      {Object.keys(Color)
        .map((color) => {
          const colorTyped = Color[color as keyof typeof Color];
          const isDisabled =
            availableColors && !availableColors.includes(colorTyped);

          // TODO: comeback to whether I want to show it, for now leave out
          if (isDisabled) {
            return null;
          }

          return (
            <option
              key={color}
              value={colorTyped}
              disabled={isDisabled}
              className="bg-gray-200"
              title={isDisabled ? "This item is currently out of stock." : null}
            >
              {typeToString(color)}
            </option>
          );
        })
        .filter((x) => x != null)}
    </select>
  );
};

export default ColorSelector;
