export enum TabCategory {
  Home = "Home",
  About = "About",
  Theme = "Theme",
  Apparel = "Apparel",
  Hats = "Hats",
  Gear = "Gear",
  Kids = "Kids",
  Podcast = "Podcast",
}
