import { Theme } from "../types/Theme";
import { Product } from "../types/Product";
import { TabCategory } from "../types/TabCategory";
import { ProductLine } from "../types/ProductLine";
import { Size } from "../types/Size";
import { Season } from "../types/Season";
import { Color } from "../types/Color";

export const Products: Product[] = [
  {
    id: 1,
    name: "The Logo Sweater",
    description: "Our custom logo on an comfortably fashionable sweater!",
    price: 79.99,
    url: "sweater-logo",
    productCategories: [TabCategory.Apparel, TabCategory.Theme],
    productLine: ProductLine.Sweaters,
    themes: [
      { theme: Theme.New, years: [2024, 2025] },
      { theme: Theme.Season, season: Season.Fall, years: [2024] },
      { theme: Theme.Season, season: Season.Winter, years: [2024] },
      { theme: Theme.Classic },
    ],
    dateOnMarket: new Date("11/12/2024"),
    images: [
      {
        color: Color.Sand,
        imagePaths: [
          "/images/products/sweater-logo/sweater-logo-sand-1.png",
          "/images/products/sweater-logo/sweater-logo-sand-2.png",
          "/images/products/sweater-logo/sweater-logo-sand-3.png",
        ],
      },
    ],
    sizes: [Size.XS, Size.S, Size.M, Size.L, Size.XL, Size.XXL, Size.XXXL],
    availableColors: [Color.Sand],
  },
  {
    id: 2,
    name: "The Logo Polo",
    description: "Our custom logo on a business casual polo!",
    price: 49.99,
    url: "polo-logo",
    productCategories: [TabCategory.Apparel, TabCategory.Theme],
    productLine: ProductLine.Polos,
    themes: [
      { theme: Theme.New, years: [2024, 2025] },
      { theme: Theme.Season, season: Season.Winter, years: [2024] },
      { theme: Theme.Season, season: Season.Spring, years: [2025] },
      { theme: Theme.Season, season: Season.Summer, years: [2025] },
      { theme: Theme.Season, season: Season.Fall, years: [2025] },
      { theme: Theme.Classic },
    ],
    dateOnMarket: new Date("11/12/2024"),
    images: [
      {
        color: Color.Navy_Blue,
        imagePaths: [
          "/images/products/polo-logo/polo-logo-blue-1.png",
          "/images/products/polo-logo/polo-logo-blue-2.png",
          "/images/products/polo-logo/polo-logo-brown-2.png",
        ],
      },
      {
        color: Color.Army_Green,
        imagePaths: [
          "/images/products/polo-logo/polo-logo-green-1.png",
          "/images/products/polo-logo/polo-logo-green-2.png",
          "/images/products/polo-logo/polo-logo-brown-2.png",
        ],
      },
      {
        color: Color.Sand,
        imagePaths: [
          "/images/products/polo-logo/polo-logo-sand-1.png",
          "/images/products/polo-logo/polo-logo-sand-2.png",
          "/images/products/polo-logo/polo-logo-brown-2.png",
        ],
      },
    ],
    sizes: [Size.XS, Size.S, Size.M, Size.L, Size.XL, Size.XXL, Size.XXXL],
    availableColors: [Color.Navy_Blue, Color.Army_Green, Color.Sand],
  },
  {
    id: 3,
    name: "Arsenal of Democracy Tank",
    description: "Want to look good at the gym?",
    price: 29.99,
    url: "tank-arsenalofdemocracy",
    productCategories: [TabCategory.Apparel, TabCategory.Theme],
    productLine: ProductLine.Tshirts,
    themes: [
      { theme: Theme.Season, season: Season.Spring, years: [2025] },
      { theme: Theme.Season, season: Season.Summer, years: [2025] },
      { theme: Theme.Classic },
    ],
    dateOnMarket: new Date("11/12/2024"),
    images: [
      {
        color: Color.Army_Green,
        imagePaths: [
          "/images/products/tanktop-arsenal/tanktop-arsenal-green-1.png",
          "/images/products/tanktop-arsenal/tanktop-arsenal-green-2.png",
          "/images/products/tanktop-arsenal/tanktop-arsenal-green-3.png",
        ],
      },
    ],
    sizes: [Size.XS, Size.S, Size.M, Size.L, Size.XL, Size.XXL, Size.XXXL],
    availableColors: [Color.Army_Green],
  },
  {
    id: 4,
    name: "Speak Softly T-Shirt",
    description: "...and carry a big stick!",
    price: 29.99,
    url: "tshirt-speaksoftly",
    productCategories: [TabCategory.Apparel, TabCategory.Theme],
    productLine: ProductLine.Tshirts,
    themes: [
      { theme: Theme.Season, season: Season.Spring, years: [2025] },
      { theme: Theme.Season, season: Season.Summer, years: [2025] },
      { theme: Theme.Classic },
    ],
    dateOnMarket: new Date("11/12/2024"),
    images: [
      {
        color: Color.Brown,
        imagePaths: [
          "/images/products/tshirt-speaksoftly/tshirt-speaksoftly-green-1.png",
          "/images/products/tshirt-speaksoftly/tshirt-speaksoftly-green-2.png",
        ],
      },
    ],
    sizes: [Size.XS, Size.S, Size.M, Size.L, Size.XL, Size.XXL, Size.XXXL],
    availableColors: [Color.Brown],
  },
  {
    id: 5,
    name: "The Logo Baseball Cap",
    description: "...hot girl summer!",
    price: 24.99,
    url: "baseball-cap-logo",
    productCategories: [TabCategory.Apparel, TabCategory.Theme],
    productLine: ProductLine.Hats,
    themes: [
      { theme: Theme.Season, season: Season.Spring, years: [2025] },
      { theme: Theme.Season, season: Season.Summer, years: [2025] },
      { theme: Theme.Classic },
    ],
    dateOnMarket: new Date("01/25/2025"),
    images: [
      {
        color: Color.Sand,
        imagePaths: ["/images/products/baseball-cap/baseball-hat-sand-1.jpg"],
      },
      {
        color: Color.Navy_Blue,
        imagePaths: ["/images/products/baseball-cap/baseball-hat-blue-1.jpg"],
      },
    ],
    sizes: [Size.XS, Size.S, Size.M, Size.L, Size.XL, Size.XXL, Size.XXXL],
    availableColors: [Color.Sand, Color.Navy_Blue],
  },
];
