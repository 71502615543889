import { TabCategory } from "../types/TabCategory";
import { ProductLine } from "../types/ProductLine";
import TabDropdown from "./TabDropdown";

const TabDropdownList = () => {
  // const currentYear = getCurrentYear();
  return (
    <>
      <TabDropdown category={TabCategory.Home} productLines={[]} />

      {/* TODO: Bryce: Themes for the future - not now */}
      {/* <TabDropdown
        category={TabCategory.Theme}
        productLines={[]}
        theme={Theme.New}
        year={currentYear}
      />
      <TabDropdown
        category={TabCategory.Theme}
        productLines={[]}
        theme={Theme.Season}
        season={getSeason(true)}
        year={currentYear}
      /> */}

      <TabDropdown
        category={TabCategory.Apparel}
        productLines={[
          ProductLine.Sweaters,
          // ProductLine.Hoodies,
          ProductLine.Polos,
          // ProductLine.Quarterzips,
          ProductLine.Tshirts,
          ProductLine.Hats, // TODO: Possibly move to own tab one day (see below)
        ]}
      />
      {/* <TabDropdown
        category={TabCategory.Hats}
        productLines={[
          ProductLine.Surfhats,
          ProductLine.Ropehats,
          ProductLine.Buckethats,
          ProductLine.Baseballcaps,
        ]}
      /> */}
      <TabDropdown category={TabCategory.Podcast} productLines={[]} />
      <TabDropdown category={TabCategory.About} productLines={[]} />
    </>
  );
};

export default TabDropdownList;
