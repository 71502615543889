import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Size } from "../types/Size";
import { Product } from "../types/Product";
import { Products } from "../data/Products";
import { useSelectedLocation } from "../contexts/SelectedLocationContext";
import SizeSelector from "../components/SizeSelector";
import ColorSelector from "../components/ColorSelector";
import { Color } from "../types/Color";
import { useCart } from "../contexts/CartContext";
import { UrlParams } from "../types/Params";
import ImageZoom from "../components/ImageZoom";

const ProductPage = () => {
  const location = useLocation();
  const { category, productLine, product } = useParams<UrlParams>();
  const { setCategory, setProductLine, setProduct } = useSelectedLocation();
  const { setIsCartOpen, addToCart } = useCart();

  const [selectedProduct] = useState<Product>(
    location.state?.selectedProduct ?? Products.find((p) => p.url === product)
  );
  const [selectedSize, setSelectedSize] = useState<Size>(null);
  const [selectedColor, setSelectedColor] = useState<Color>(
    selectedProduct?.images[0]?.color
  );
  const [selectedImagePath, setSelectedImagePath] = useState<string>(
    selectedProduct?.images?.length > 0
      ? selectedProduct?.images.find((i) => i.color === selectedColor)
          .imagePaths[0]
      : null
  );

  const handleAddToCart = () => {
    setIsCartOpen(true);
    addToCart({
      product: selectedProduct,
      quantity: 1,
      size: selectedSize,
      color: selectedColor,
    });
  };

  useEffect(() => {
    setCategory(category);
    setProductLine(productLine);
    setProduct(product);
  }, [category, productLine, product, setCategory, setProductLine, setProduct]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top
  }, []); // Runs only once when the component mounts

  return (
    <div className="product-page-container">
      {/* Image Gallery on the Left */}
      <div className="max-w-full h-auto col-span-1 lg:col-span-1 flex lg:flex-col lg:space-y-4 overflow-x-auto lg:overflow-visible">
        {selectedProduct?.images
          ?.find((i) => i.color === selectedColor)
          ?.imagePaths?.map((image, index) => (
            <img
              key={index}
              src={image + "?v=1"}
              alt={`${selectedProduct?.name}_${index}`}
              className={`object-cover cursor-pointer border w-24 h-24 md:w-32 md:h-32 lg:w-full lg:h-auto ${
                selectedImagePath === image
                  ? "border-red-500"
                  : "border-gray-200"
              }`}
              onClick={() => setSelectedImagePath(image)}
              loading="lazy"
            />
          ))}
      </div>

      {/* Featured Image in the Center */}
      <div className="col-span-1 lg:col-span-4">
        <ImageZoom
          imageUrl={selectedImagePath}
          imageAlt={`${selectedProduct?.name}_center`}
        />
      </div>

      {/* Product Details on the Right */}
      <div className="col-span-1 lg:col-span-3 flex flex-col space-y-4">
        <h2 className="text-2xl font-semibold">{selectedProduct?.name}</h2>
        <p className="text-lg text-gray-600">{selectedProduct?.description}</p>
        <div className="space-y-2">
          <label htmlFor="size" className="text-lg font-bold block">
            Size
          </label>
          <SizeSelector
            selectedSize={selectedSize}
            onSizeSelect={(size) => setSelectedSize(size)}
          />
          <label htmlFor="color" className="text-lg font-bold block">
            Color
          </label>
          <ColorSelector
            selectedColor={selectedColor}
            onColorSelect={(color) => {
              // 1. Set Color
              setSelectedColor(color);
              // 2. After choosing a color - switch selectedImagePath
              setSelectedImagePath(
                selectedProduct?.images?.length > 0
                  ? selectedProduct?.images.find((i) => i.color === color)
                      .imagePaths[0]
                  : null
              );
            }}
            availableColors={selectedProduct?.availableColors}
          />
        </div>
        <button
          type="button"
          className="cart-add-button"
          onClick={handleAddToCart}
          disabled={!selectedSize}
        >
          Add to Cart
        </button>
      </div>
    </div>
  );
};

export default ProductPage;
