import { useState } from "react";
import { ProductLine } from "../types/ProductLine";
import { TabCategory } from "../types/TabCategory";
import { Link } from "react-router-dom";
import {
  getCategoryTitle,
  getLink,
  parameterToCategoryType,
  parameterToProductLineType,
  parameterToThemeType,
} from "../extensions/helpers";
import { useSelectedLocation } from "../contexts/SelectedLocationContext";
import { Season } from "../types/Season";
import { Theme } from "../types/Theme";

type TabDropdownProps = {
  category: TabCategory;
  productLines: ProductLine[];
  theme?: Theme;
  season?: Season;
  year?: number;
};

const TabDropdown = ({
  category,
  productLines,
  theme,
  season,
  year,
}: TabDropdownProps) => {
  const {
    category: selectedCategory,
    productLine: selectedProductLine,
    theme: selectedTheme,
  } = useSelectedLocation();

  const [isTabOpen, setIsTabOpen] = useState(false);

  const isTheme =
    !!theme &&
    TabCategory[category as keyof typeof TabCategory] === TabCategory.Theme;

  return (
    <div className="relative">
      <Link
        to={getLink({ category, theme, season, year })}
        key={category}
        onMouseEnter={() => setIsTabOpen(true)}
        className={`tab-button ${
          isTheme
            ? "oswald-text text-red-600 text-12"
            : "merriweather-text tracking-wide"
        } ${
          parameterToCategoryType(selectedCategory) === category &&
          (isTheme ? parameterToThemeType(selectedTheme) === theme : true)
            ? "border-b-2 border-yellow-900 text-yellow-800"
            : "text-gray-700 hover:border-b-2 hover:border-gray-300"
        }`}
      >
        {isTheme ? getCategoryTitle(theme, season, year) : category}
        {productLines.length > 0 && <span className="arrow-down" />}
      </Link>

      {/* NOT Handling Themes in any sub-list */}
      {isTabOpen && productLines.length > 0 && (
        <div className="dropdown-content">
          {productLines.map((productLine) => (
            <Link
              to={getLink({ category, productLine })}
              key={productLine}
              className={`dropdown-item ${
                parameterToProductLineType(selectedProductLine) === productLine
                  ? "border-b-2 border-yellow-900 text-yellow-800"
                  : "text-gray-700 hover:border-b-2 hover:border-gray-300"
              }`}
            >
              {ProductLine[productLine as keyof typeof ProductLine]}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default TabDropdown;
