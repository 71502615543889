export enum Size {
  XS = "XS",
  S = "S",
  M = "M",
  L = "L",
  XL = "XL",
  XXL = "XXL",
  XXXL = "XXXL",
  // XXXXL = "XXXXL",
  // XXXXXL = "XXXXXL",
}
