import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getCategoryTitle, getLink } from "../extensions/helpers";
import { CarouselImage } from "../types/CarouselImage";

type CarouselProps = {
  images: CarouselImage[];
  interval?: number;
};

const Carousel = ({ images, interval = 8000 }: CarouselProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, interval);

    return () => clearInterval(timer);
  }, [images.length, interval]);

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handleDotClick = (index: number) => {
    setCurrentIndex(index);
  };

  const currentImage = images[currentIndex];

  return (
    <>
      <button className="carousel-button prev" onClick={handlePrev}>
        <img
          src="/images/arrow-left.png"
          alt={`Left Arrow`}
          className="w-6 h-10 ml-2"
        />
      </button>
      <picture className="w-full h-full">
        <source
          srcSet={currentImage.smallImagePath}
          media="(max-width: 600px)"
          className={
            currentImage.smallClassName ?? "carousel-image-scaled-down"
          }
        />
        <img
          src={currentImage.imagePath}
          alt={`Slide ${currentIndex + 1}`}
          className={currentImage.className ?? "carousel-image-scaled-down"}
        />
      </picture>
      {currentImage.category && currentImage.theme && (
        <Link
          to={getLink({
            category: currentImage.category,
            theme: currentImage.theme,
            season: currentImage.season,
            year: currentImage.year,
          })}
          className="carousel-center-button"
        >
          Shop{" "}
          {getCategoryTitle(
            currentImage.theme,
            currentImage.season,
            currentImage.year
          )}
        </Link>
      )}
      <button className="carousel-button next" onClick={handleNext}>
        <img
          src="/images/arrow-right.png"
          alt={`Right Arrow`}
          className="w-6 h-10 ml-4"
        />
      </button>
      <div className="carousel-indicators">
        {images.map((_, index) => (
          <span
            key={index}
            className={`carousel-indicator ${
              currentIndex === index ? "active" : ""
            }`}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </div>
    </>
  );
};

export default Carousel;
