import React, { useEffect, useRef, useCallback } from "react";
import Header from "../components/Header";
import Cart from "../components/Cart";
import AboutPage from "./AboutPage";
import HomePage from "./HomePage";
import { Route, Routes } from "react-router-dom";
import ProductLinePage from "./ProductLinePage";
import { useSearch } from "../contexts/SearchContext";
import ProductPage from "./ProductPage";
import { useCart } from "../contexts/CartContext";
import HamburgerMenu from "../components/HamburgerMenu";
import { useHamburgerMenu } from "../contexts/HamburgerMenuContext";
import SearchMenu from "../components/SearchMenu";
import PodcastPage from "./PodcastPage";

const MainPage: React.FC = () => {
  const { isCartOpen, setIsCartOpen } = useCart();
  const { isHamburgerMenuOpen, setIsHamburgerMenuOpen } = useHamburgerMenu();
  const { isSearchMenuOpen, setIsSearchMenuOpen } = useSearch();

  const cartRef = useRef<HTMLDivElement>(null);
  const hamburgerMenuRef = useRef<HTMLDivElement>(null);
  const searchMenuRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (cartRef.current && !cartRef.current.contains(event.target as Node)) {
        setIsCartOpen(false);
      }
      if (
        hamburgerMenuRef.current &&
        !hamburgerMenuRef.current.contains(event.target as Node)
      ) {
        setIsHamburgerMenuOpen(false);
      }
      if (
        searchMenuRef.current &&
        !searchMenuRef.current.contains(event.target as Node)
      ) {
        setIsSearchMenuOpen(false);
      }
    },
    [setIsCartOpen, setIsHamburgerMenuOpen, setIsSearchMenuOpen]
  );

  useEffect(() => {
    if (isCartOpen || isHamburgerMenuOpen || isSearchMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside, isCartOpen, isHamburgerMenuOpen, isSearchMenuOpen]);

  // prettier-ignore
  return (
    <>
      <div className={`app-container ${isCartOpen && "opacity-75"}`}>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/podcast" element={<PodcastPage />} />

          {/* Themes */}
          <Route path="/:category/:theme/seasons/:season/year/:year" element={<ProductLinePage />} />
          <Route path="/:category/:theme/year/:year" element={<ProductLinePage />} />
          <Route path="/:category/:theme" element={<ProductLinePage />} />
         
          {/* Clothing */}
          <Route path="/:category/productlines/:productLine/products/:product" element={<ProductPage />}/>
          <Route path="/:category/productlines/:productLine" element={<ProductLinePage />} />
          <Route path="/:category" element={<ProductLinePage />} />
        </Routes>
      </div>

      {/* Side Menus */}
      {isCartOpen && (
        <div
          ref={cartRef}
          className="fixed right-0 top-0 p-4 w-80 bg-white shadow-lg z-50"
        >
          <Cart />
        </div>
      )}

      {isHamburgerMenuOpen && (
        <div ref={hamburgerMenuRef}>
          <HamburgerMenu />
        </div>
      )}

      {isSearchMenuOpen && (
        <div ref={searchMenuRef}>
          <SearchMenu />
        </div>
      )}
    </>
  );
};

export default MainPage;
