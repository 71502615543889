import React, { useEffect } from "react";
import { useSelectedLocation } from "../contexts/SelectedLocationContext";
import ContactUs from "../components/ContactUs";
import { TabCategory } from "../types/TabCategory";

const AboutPage: React.FC = () => {
  const {
    setCategory,
    setProductLine,
    setProduct,
    setTheme,
    setSeason,
    setYear,
  } = useSelectedLocation();

  useEffect(() => {
    setCategory(TabCategory.About.toString());
    setProductLine(null);
    setProduct(null);
    setTheme(null);
    setSeason(null);
    setYear(null);
  }, [setCategory, setProduct, setProductLine, setSeason, setTheme, setYear]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top
  }, []); // Runs only once when the component mounts

  return (
    <div className="about-page">
      <section className="hero">
        <h1>About Us</h1>
        <p>
          We here at The Americana Company want to create beautiful historic
          apparel and items.
        </p>
      </section>
      <section className="content">
        <h2 className="text-xl">Coming Soon...</h2>

        {/* TODO: Come back to this on who to display */}
        {/* <div className="team">
          <div className="team-member">
            <img src="/team-member1.jpg" alt="Partner" />
            <h3>Bryce Robins</h3>
            <p>CEO & Founder</p>
          </div>
          <div className="team-member">
            <img src="/team-member2.jpg" alt="Partner" />
            <h3>Paul Miller</h3>
            <p>CTO</p>
          </div>
          <div className="team-member">
            <img src="/team-member3.jpg" alt="Partner" />
            <h3>Dylan Aponte</h3>
            <p>COO</p>
          </div>
          <div className="team-member">
            <img src="/team-member2.jpg" alt="Partner" />
            <h3>Anelisa Rosario</h3>
            <p>Chief Designer</p>
          </div>
        </div> */}
      </section>
      <section>
        <ContactUs />
      </section>
    </div>
  );
};

export default AboutPage;
