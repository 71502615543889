import { CarouselImage } from "../types/CarouselImage";
import { TabCategory } from "../types/TabCategory";
import { Theme } from "../types/Theme";

export const HomePageCarouselImages: CarouselImage[] = [
  {
    imagePath: "/images/main-design-carousel.png",
    smallImagePath: "/images/main-design-carousel-sm.png",
    className: "carousel-image-full",
    smallClassName: "carousel-image-scaled-down",
  },
  {
    imagePath: "/images/logo-title-bottom-narrow.png",
    category: TabCategory.Theme,
    theme: Theme.Classic,
  },
  { imagePath: "/images/products/sweater-logo/sweater-logo-sand-3.png" },
  { imagePath: "/images/products/polo-logo/polo-logo-brown-2.png" },
  { imagePath: "/images/products/tanktop-arsenal/tanktop-arsenal-green-3.png" },
  {
    imagePath:
      "/images/products/tshirt-speaksoftly/tshirt-speaksoftly-brown-2.png",
  },
];
