import React, { useEffect } from "react";
import { useSelectedLocation } from "../contexts/SelectedLocationContext";
import { TabCategory } from "../types/TabCategory";

const PodcastPage: React.FC = () => {
  const {
    setCategory,
    setProductLine,
    setProduct,
    setTheme,
    setSeason,
    setYear,
  } = useSelectedLocation();

  useEffect(() => {
    setCategory(TabCategory.Podcast.toString());
    setProductLine(null);
    setProduct(null);
    setTheme(null);
    setSeason(null);
    setYear(null);
  }, [setCategory, setProduct, setProductLine, setSeason, setTheme, setYear]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top
  }, []); // Runs only once when the component mounts

  return (
    <div className="podcast-page">
      <section className="hero">
        <h1>The Americana Podcast</h1>
        <p>
          We are creating a podcast to discuss the culture and history of
          America to go along with our product line!
        </p>
      </section>
      <section className="content">
        <h2 className="text-xl">Coming Soon...</h2>
      </section>
    </div>
  );
};

export default PodcastPage;
